<template>
  <div>
    <p v-if="checkSensor">Checking Sensor</p>
    <p v-else-if="removingSensor">Removing Sensor</p>
    <p v-else-if="sensorRemoved">Sensor Removed</p>
    <p v-else-if="sensorDNE">Sensor Doesn't Exist</p>
  </div>
</template>

<script>
export default {
  name: "removeDevice",
  data() {
    return {
      checkSensor: true,
      removingSensor: false,
      sensorRemoved: false,
      sensorDNE: false
    };
  },
  components: {},
  async created() {
    console.log("checking sensor");
    let sensor = await this.$store.dispatch(
      "sensor/getSingleSensor",
      this.$route.params.id
    );
    this.checkSensor = false;
    if (sensor) {
      console.log("removing sensor");
      this.removingSensor = true;
      let removedSensor = await this.$store.dispatch(
        "sensor/removeSensor",
        sensor.id
      );
      if (removedSensor) {
        console.log("sensor removed");
        this.removingSensor = false;
        this.sensorRemoved = true;
      }
    } else {
      console.log("sensor doesn't exist");
      this.sensorDNE = true;
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss">
</style>